/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { User, getAuth, sendEmailVerification } from 'firebase/auth'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import { usePostHog } from 'posthog-js/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import CookieConsentPopup from 'src/components/Cookies/CookieConsent'
import useCacheUpdater from 'src/hooks/useCacheUpdater'
import useUserLocation from 'src/hooks/useUserLocation'
import { RedirectUrls } from 'src/types/types'
import ga from 'src/utils/pixels/gtag'
import { getHsq } from 'src/utils/pixels/hubspot'
import { removeTokenCookie } from '../../apollo-client'
import Login from '../../components/Login'
import { USER_CHANGES_FRAGMENT, USER_FRAGMENT } from '../../constants/fragments'
import firebase from '../../firebase/firebaseClient'
import {
  AuthEmailType,
  IMutation,
  IQuery,
  IQueryCurrentUserArgs,
  IUser,
  Maybe
} from '../../types/graphql'
import useLogger from '../LoggerProvider/useLogger'
import { AuthContext, IAuthContext } from './index'

const ModalDrawer = dynamic(() => import('src/components/ModalDrawer'))

const CURRENT_USER = gql`
  ${USER_FRAGMENT}
  ${USER_CHANGES_FRAGMENT}
  query CurrentUser($referrer: String) {
    currentUser(referrer: $referrer) {
      ...CoreUserFields
      currency
      language
      referrer
      referrerStatus
      userChanges {
        ...UserChangesFields
      }
    }
  }
`

const SEND_AUTH_EMAIL = gql`
  mutation SendAuthEmail(
    $email: String!
    $emailType: AuthEmailType!
    $redirectUrl: String
  ) {
    sendAuthEmail(
      email: $email
      emailType: $emailType
      redirectUrl: $redirectUrl
    )
  }
`

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [cookiesDealt, setCookiesDealt] = useState<boolean>(false)
  const auth = useAuth()

  const toggleLoginModal = useCallback(
    (open?: boolean) => {
      if (open !== undefined) setModalOpen(open)
      else setModalOpen(!modalOpen)
    },
    [modalOpen]
  )

  useEffect(() => {
    if (auth.isAuthenticated) {
      setModalOpen(false)
    }
  }, [auth.isAuthenticated])

  const value = useMemo(
    () => ({
      ...auth,
      toggleLoginModal,
      cookiesDealt
    }),
    [auth, toggleLoginModal, cookiesDealt]
  )

  return (
    <AuthContext.Provider value={value}>
      <CookieConsentPopup setCookiesDealt={setCookiesDealt} />
      <ModalDrawer
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Inicia sesión o regístrate"
        sx={{ maxWidth: '600px' }}
      >
        <Login isModal />
      </ModalDrawer>
      {children}
    </AuthContext.Provider>
  )
}

export default React.memo(AuthProvider)

/**
 * Hook
 */

export const useAuth = (): Omit<
  IAuthContext,
  'toggleLoginModal' | 'cookiesDealt'
> => {
  const router = useRouter()
  const logger = useLogger()
  const posthog = usePostHog()
  const { userAddress } = useUserLocation()

  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth)

  const [referrer, setReferrer] = useState<string>()
  useEffect(() => {
    if (user) return
    if (!router.isReady) return

    const queryNanoId =
      typeof router.query.nid === 'string' ? (router.query.nid as string) : null

    if (queryNanoId) {
      setReferrer(queryNanoId)
      setCookie(null, 'referrer', queryNanoId, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/'
      })
    } else {
      const storageNanoId = parseCookies(null).referrer
      if (storageNanoId) {
        setReferrer(storageNanoId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  if (error) {
    logger.error('Error retrieving firebase auth.', error)
  }

  const [data, setData] = useState<Pick<IQuery, 'currentUser'> | undefined>()
  const [getCurrentUser, { loading: loadingCurrentUser, refetch }] =
    useLazyQuery<Pick<IQuery, 'currentUser'>, IQueryCurrentUserArgs>(
      CURRENT_USER,
      {
        onCompleted: (data) => {
          setData(data)
        },
        onError: (error) => {
          logger.error('Error retrieving currentUser', error)
        },
        fetchPolicy: 'cache-and-network'
      }
    )

  const updateCurrentUser = useCacheUpdater<
    Pick<IQuery, 'currentUser'>,
    IQueryCurrentUserArgs
  >({
    query: CURRENT_USER,
    variables: { referrer }
  })

  const [sendAuthEmail] = useMutation<Pick<IMutation, 'sendAuthEmail'>>(
    SEND_AUTH_EMAIL,
    { onError: (error) => logger.error('Error sending auth email', error) }
  )

  useEffect(() => {
    if (user) {
      setUserTokenCookie(user)
      getCurrentUser({ variables: { referrer } })
      ga.setUser(user.uid)
    } else {
      removeTokenCookie()
      if (data?.currentUser) refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentUser, user, referrer, refetch])

  useEffect(() => {
    if (!user) return
    if (typeof window === 'undefined') return

    const {
      email: userEmail,
      userChanges,
      firstname,
      lastname,
      phoneNumber
    } = data?.currentUser ?? {}
    const email = userEmail ?? user.email ?? userChanges?.email
    const phone_number =
      user.phoneNumber ?? phoneNumber ?? userChanges?.phoneNumber
    const name = firstname ?? userChanges?.firstname ?? user.displayName
    const lName = lastname ?? userChanges?.lastname ?? ''

    const _hsq = getHsq()
    if (_hsq && email) _hsq.push(['identify', { email }])

    if (posthog.__loaded) posthog.identify(user.uid, { email, name })

    const gtag = ga.getGtag()
    gtag('set', 'user_data', {
      email,
      phone_number,
      address:
        name && lName && userAddress?.zipcode && userAddress?.country
          ? {
              first_name: name,
              last_name: lName,
              street: userAddress?.street1,
              city: userAddress?.city,
              region: userAddress?.state,
              postal_code: userAddress?.zipcode,
              country: userAddress?.country
            }
          : undefined
    })

    const dataLayer = ga.getDataLayer()
    dataLayer.push({ user_id: user.uid, email })
  }, [user, data, posthog, userAddress])

  useEffect(() => {
    if (!user || !router.isReady) return

    let verified = false
    user.providerData.forEach((profile) => {
      if (profile?.providerId === 'phone') verified = true
      if (profile?.providerId === 'google.com') verified = true
      if (profile?.providerId === 'facebook.com') verified = true
      if (profile?.providerId === 'password') verified = user.emailVerified
    })

    const pathname = router.pathname
    const privatePaths = [
      RedirectUrls.Dashboard,
      RedirectUrls.DashboardRent,
      RedirectUrls.UserConfirmation
    ]
    const isPrivatePath = privatePaths.some((path) => pathname.includes(path))
    const isPathConfirm = pathname.includes('/confirm-email')
    if (!isPrivatePath || isPathConfirm) return
    if (verified) return

    logger.warn('Email not verified, redirecting to confirm-email', {
      user,
      pathname
    })
    const redirect = window.location.pathname + window.location.search
    const redirectQuery = redirect ? `?redirect=${redirect}` : ''

    const emailVeriSent = parseCookies(null).emailVerificationSent
    if (emailVeriSent) {
      router.push('/confirm-email' + redirectQuery)
      return
    }

    setCookie(null, 'emailVerificationSent', 'true', { maxAge: 600 })
    sendEmailVerification(user)
      .catch((err) => logger.error('Error sending email verification', err))
      .finally(() => router.push('/confirm-email' + redirectQuery))
  }, [user, router, logger])

  const value = useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
      loading,
      loadingCurrentUser,
      currentUser: (user && data
        ? data.currentUser
        : undefined) as Maybe<IUser>,
      sendAuthEmail: async (
        email: string,
        emailType: AuthEmailType,
        redirectUrl?: string
      ) => sendAuthEmail({ variables: { email, emailType, redirectUrl } }),
      logout: async () => {
        setData(undefined)
        return firebase
          .auth()
          .signOut()
          .then(() => {
            removeTokenCookie()
          })
          .catch((error) => {
            logger.error('Error logging out', error)
          })
      },
      refetchCurrentUser: async () => {
        refetch()
      },
      updateCurrentUser,
      referrer,
      setReferrer
    }),
    [
      user,
      loading,
      loadingCurrentUser,
      data,
      updateCurrentUser,
      referrer,
      sendAuthEmail,
      logger,
      refetch
    ]
  )

  return value
}

async function setUserTokenCookie(user: User): Promise<void> {
  const token = await user.getIdToken()
  setCookie(null, 'token', token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/'
  })
}
